import {
    Batch,
    SankalpTimeInfo,
    VideoData,
} from '@a4b/api/src/modules/Monetisation/Puja/types'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { CaretRightOutlined } from "@ant-design/icons"
import { Button, Checkbox, Collapse, Form, Input, message, Modal, Row, Spin, Tag, Typography } from "antd"
import React, { useCallback, useEffect, useState } from 'react'
import ReactPlayer from "react-player"
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import { useAppContext } from '@/components/AppContext'
import { Values } from './type'
import { prepareNameTaggingPayload, SANKALP_VIDEO_STATUS, timeFormat } from '../../utils/helper'
import { FormInstance } from 'antd/es/form'
import { CustomLoader } from '@/components/Loader'

const { Title } = Typography
const { Panel } = Collapse

interface SankalpNameTaggingProps {
    form: FormInstance
    batchData: Batch | undefined
    handleTabChange: (key: string) => void
}
interface FieldState {
    start_time: string
    end_time: string
}

const SankalpNameTagging: React.FC<SankalpNameTaggingProps> = ({ form, batchData, handleTabChange }) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [videoUrl, setVideoUrl] = useState('')
    const [sankalpTimeInfo, setSankalpTimeInfo] = useState<SankalpTimeInfo[] | null>(null)
    const [existingFormData, setExistingFormData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
    const [selectedOrder, setSelectedOrder] = useState<any[]>([])

    const fetchSankalpTimeInfo = useCallback(async () => {
        setLoading(true)
        try {
            const sankalpTimeInfoResponse = await monetisationPujaApi.batchApi.getSankalpTimeInfo(batchData?.batch_id!)
            message.success('Fetched SankalpTimeInfo', 2)
            const sankalpTimeData = sankalpTimeInfoResponse?.data?.data
            setSankalpTimeInfo(sankalpTimeData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error('Error while Fetching latest batch data', 2)
            setSankalpTimeInfo(null)
        }
    }, [batchData?.batch_id, monetisationPujaApi.batchApi])

    useEffect(() => {
        if (batchData?.batch_id)
            fetchSankalpTimeInfo()
        // if (!batchData?.video_tagging_status)
        //     return
        // if (batchData?.video_tagging_status !== SANKALP_VIDEO_STATUS.processing)

    }, [batchData?.batch_id, fetchSankalpTimeInfo])

    useEffect(() => {
        console.log('formData initialized')
        const formData = JSON.parse(localStorage?.getItem?.(batchData?.batch_id!)!)
        console.log({ formData }, 'formData')
        if (formData)
            setExistingFormData(formData)
    }, [batchData?.batch_id])

    const transformData = useCallback(() => {
        const memberData = batchData?.pooja_member_data?.map((item) => {
            const matchingSankalp = sankalpTimeInfo?.find(sankalp => sankalp?.order_id === item?.order_id)
            return {
                order_id: item?.order_id ?? '',
                status: item?.status === 'to_be_rescheduled',
                booking_id: matchingSankalp?.booking_id || '',
                gotra: item?.members?.length > 0 ? item?.members[0]?.gotra || '' : '',
                names: item?.members?.map(member => member?.full_name || '')?.join(', '),
                start_time: matchingSankalp?.start_time || '',
                end_time: matchingSankalp?.end_time || ''
            }
        }) || []
        return { pooja_member_data: memberData }
    }, [batchData?.pooja_member_data, sankalpTimeInfo])

    // useEffect(() => {
    //     const data = transformData()
    //     form.setFieldsValue(data)
    // }, [form, transformData])

    useEffect(() => {
        const data = transformData()
        form.setFieldsValue(data)
        setSelectedOrder(data?.pooja_member_data)
    }, [form, transformData])

    const onFormFieldsChange = () => {
        const rawVideoField = form.getFieldValue('raw_video')
        if (rawVideoField && rawVideoField?.length > 0) {
            const uploadedUrl = rawVideoField[0]
            setVideoUrl(uploadedUrl)
        }
    }

    const handleUpload = async (payload: VideoData, formData: Values) => {
        const sankalpform = JSON.stringify(formData)
        try {
            await monetisationPujaApi.batchApi.uploadTagNames(batchData?.batch_id!, payload)
            message.success("Name tagging uploaded successfully")
            localStorage?.removeItem?.(batchData?.batch_id!)
            setExistingFormData(null)
            form.resetFields()
            handleTabChange('1')
        } catch (error: any) {
            console.log('Error while updating:', error.response.data.error)
            if (error.response.data.error?.args) {
                const args = error.response.data?.error?.args
                Object.keys(args)?.forEach((key) => {
                    args?.[key]?.forEach((msg: string) => {
                        message.error(msg, 3)
                    })
                })
            } else {
                message.error(error.response.data.error?.message)
            }
            localStorage.setItem(batchData?.batch_id!, sankalpform)
            setExistingFormData(JSON.parse(sankalpform))
        }
    }

    const handleValidateForm = (values: Values): string[] => {
        const invalidBookingIds: string[] = []
        const poojaMemberData = values?.pooja_member_data || []

        poojaMemberData?.forEach((item: any) => {
            const startTime = item?.start_time
            const endTime = item?.end_time

            if ((startTime && !endTime) || (!startTime && endTime)) {
                invalidBookingIds?.push?.(item?.booking_id)
            }
        })

        return invalidBookingIds
    }

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then((values: Values) => {
                const invalidBookingIds = handleValidateForm(values)
                if (invalidBookingIds?.length > 0) {
                    message.info({
                        key: 'custom_error',
                        content: (
                            <span>
                                {`Please fill both start_time and end_time for booking IDs:[${invalidBookingIds?.join(', ')}]`}
                                <Button type="link" onClick={() => message.destroy('custom_error')}>Close</Button>
                            </span>
                        ),
                        duration: 0,
                    })
                } else {
                    const payload = prepareNameTaggingPayload(values)
                    if (batchData?.video_tagging_status !== SANKALP_VIDEO_STATUS.PROCESSING) {
                        handleUpload(payload, values)
                    } else {
                        message.warning('Video is still processing, please wait until it is processed', 3)
                    }
                }
            })
            .catch((error: any) => {
                console.log('error', { error })
                console.log('Sankalp name tagging form error: ' + error)
            })
    }

    const handleStartTimeChange = (index: number, value: string) => {
        const fields = form.getFieldValue('pooja_member_data') as FieldState[]
        const newFieldsState = [...fields]

        newFieldsState[index].start_time = value

        if (index > 0) {
            newFieldsState[index - 1].end_time = value
        }

        // Handle edge case: Fill end_time for skipped start_time fields
        for (let i = index - 1; i >= 0; i--) {
            if (newFieldsState[i].start_time && !newFieldsState[i].end_time) {
                newFieldsState[i].end_time = value
                break
            }
        }

        form.setFieldsValue({ pooja_member_data: newFieldsState })
    }

    const handleEndTimeChange = (index: number, value: string) => {
        const fields = form?.getFieldValue('pooja_member_data') as FieldState[]
        const newFieldsState = [...fields]

        newFieldsState[index].end_time = value

        form.setFieldsValue({ pooja_member_data: newFieldsState })
    }

    const handlePrefillForm = () => {
        if (existingFormData) {
            form.setFieldsValue(existingFormData)
        }
    }

    const handleResetForm = () => {
        form.resetFields()
        fetchSankalpTimeInfo()
    }

    const handleValuesChange = useCallback((changedValues: any, allValues: any) => {
        console.log({ changedValues });
        console.log({ allValues });

        const changedIndex = Object.keys(changedValues?.pooja_member_data || {})[0]; // This gives the index of the changed item

        const changedMember = allValues?.pooja_member_data?.[changedIndex];

        if (changedMember?.status) {
            setSelectedOrder([changedMember])
            setShowConfirmationModal(true)
        } else {
            setSelectedOrder([])
        }
    }, []);

    const handleCancelModal = useCallback(() => {
        setShowConfirmationModal(false);

        const allFields = form?.getFieldValue('pooja_member_data') || [];

        const updatedFields = allFields?.map((item: any) => {
            const isSelected = selectedOrder?.some(selected => selected?.order_id === item?.order_id);
            if (isSelected) {
                return {
                    ...item,
                    status: false,
                };
            }
            return item
        });

        form.setFieldsValue({ pooja_member_data: updatedFields });
    }, [form, selectedOrder]);


    const handleUpdateOrderState = useCallback(async (orderId: string) => {
        try {
            await monetisationPujaApi.rescheduleApi.updateOrderState(orderId, { state: 'to_be_rescheduled' })
            setSelectedOrder((prevOrders) =>
                prevOrders?.map((order) =>
                    order?.order_id === orderId
                        ? { ...order, status: 'to_be_rescheduled' }
                        : order
                )
            );
            message.success('Order state updated successfully')
        } catch (err) {
            message.error('Error while updating order state')
        }
    }, [monetisationPujaApi.rescheduleApi])

    const handleOk = useCallback(async () => {
        console.log('Making API call with selected items:', selectedOrder)
        await handleUpdateOrderState(selectedOrder?.[0]?.order_id)
        setShowConfirmationModal(false)
    }, [handleUpdateOrderState, selectedOrder])

    console.log({ selectedOrder })
    console.log(form.getFieldValue(['pooja_member_data']), 'form')

    return (
        <>
            <Form
                form={form} name="pooja_form"
                layout="vertical"
                onFieldsChange={onFormFieldsChange}
                onValuesChange={handleValuesChange}
            >
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2']}
                    activeKey={['1', '2']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                >
                    <Panel header="Raw video" key="1" className="site-collapse-custom-panel" style={{ position: 'sticky', top: '-25px', zIndex: 1, background: '#FAFAFA' }}>
                        <Row style={{ width: '100%', alignItems: 'start' }}>
                            <Form.Item
                                label={<Title level={5} >Upload raw video</Title>}
                                name='raw_video'
                                labelCol={{ span: 14 }}
                                wrapperCol={{ span: 14 }}
                                valuePropName='fileList'
                                style={{ marginTop: '10px', width: '30%' }}
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    allowedFileExtension={[
                                        AllowedFormats.MP4,
                                    ]}
                                    min={0}
                                    max={1}
                                    maxSizeInKiloBytes={9145728}
                                    disabled={batchData?.video_tagging_status === SANKALP_VIDEO_STATUS.PROCESSING}
                                />
                            </Form.Item>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                {videoUrl &&
                                    <>
                                        <Title level={5} >Preview</Title>
                                        <ReactPlayer
                                            url={videoUrl}
                                            width='440px'
                                            height='250px'
                                            controls
                                        />
                                    </>
                                }
                            </div>
                        </Row>
                    </Panel>
                    <Panel header={
                        <Row justify='space-between' style={{ width: '100%' }}>
                            <span>Name — Timestamp tagging</span>
                            {existingFormData &&
                                <Row>
                                    <Button type='primary' onClick={handlePrefillForm}>Prefill form</Button>
                                    <Button style={{ marginLeft: '10px' }} type='default' onClick={handleResetForm}>Reset form</Button>
                                </Row>
                            }
                        </Row>
                    }
                        key="2" className="site-collapse-custom-panel">
                        <Row justify='space-between' style={{ width: '100%', alignItems: 'end' }}>
                            <Title level={5} >To be reschedule</Title>
                            <Title level={5} >Booking ID</Title>
                            <Title level={5} >Order ID</Title>
                            <Title level={5} >Gotra</Title>
                            <Title level={5} >Names</Title>
                            <Title level={5} >Start Time</Title>
                            <Title level={5} >End Time</Title>
                        </Row>
                        {
                            loading ?
                                <Row style={{ width: '100%', justifyContent: 'center' }}>
                                    <Spin indicator={<CustomLoader />} />
                                </Row>
                                :
                                <>
                                    <Row justify='space-around' style={{ width: '100%' }}>
                                        <Form.List name={['pooja_member_data']}>
                                            {(fields) => (
                                                <>
                                                    {fields.map((field: any, index: number) => {
                                                        return (
                                                            <Row justify='space-between' key={index} style={{ width: '100%' }}>
                                                                <Form.Item valuePropName='checked' name={[field.name, 'status']}>
                                                                    <Checkbox
                                                                        disabled={form.getFieldValue(['pooja_member_data', field.name, 'status']) === true}
                                                                        style={{ lineHeight: '32px' }}
                                                                    >
                                                                        <span style={{ textDecoration: 'underline', color: form.getFieldValue(['pooja_member_data', field.name, 'status']) === true ? 'grey' : '#1990FF' }}>
                                                                            {form.getFieldValue(['pooja_member_data', field.name, 'status']) === true ? 'Rescheduled' : 'Reschedule'}
                                                                        </span>
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'booking_id']}
                                                                >
                                                                    <Input readOnly={true} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'order_id']}
                                                                >
                                                                    <Input readOnly={true} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'gotra']}
                                                                >
                                                                    <Input readOnly={true} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'names']}
                                                                >
                                                                    <Input readOnly={true} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'start_time']}
                                                                    style={{ flexBasis: '10%' }}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: 'Please enter start time',
                                                                        },
                                                                        ({ getFieldValue }: { getFieldValue: FormInstance['getFieldValue'] }) => ({
                                                                            validator(_: any, value: string) {
                                                                                if (!value || timeFormat.test(value)) {
                                                                                    return Promise.resolve()
                                                                                }
                                                                                return Promise.reject(new Error('Invalid time format'))
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        value={form.getFieldValue(['pooja_member_data', field.name, 'start_time'])}
                                                                        onChange={(e) => handleStartTimeChange(index, e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'end_time']}
                                                                    style={{ flexBasis: '10%' }}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: 'Please enter end time',
                                                                        },
                                                                        ({ getFieldValue }: { getFieldValue: FormInstance['getFieldValue'] }) => ({
                                                                            validator(_: any, value: string) {
                                                                                if (!value || timeFormat.test(value)) {
                                                                                    return Promise.resolve()
                                                                                }
                                                                                return Promise.reject(new Error('Invalid time format'))
                                                                            },
                                                                        }),
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        value={form.getFieldValue(['pooja_member_data', field.name, 'end_time'])}
                                                                        onChange={(e) => handleEndTimeChange(index, e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </Form.List>
                                    </Row>
                                    {
                                        form?.getFieldValue('pooja_member_data')?.length > 0 &&
                                        <Form.Item>
                                            <Button type="primary" onClick={handleFormSubmit}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    }
                                </>

                        }
                    </Panel>
                </Collapse >
            </Form >
            <Modal
                title={<span>Reschedule Confirmation</span>}
                visible={showConfirmationModal}
                onOk={handleOk}
                onCancel={handleCancelModal}
                okText='Reschedule'
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {selectedOrder?.map((item, index) => (
                        <li key={index}>
                            <strong>Booking ID:</strong> <Tag color='blue'>{item?.booking_id}</Tag>
                        </li>
                    ))}
                    <span style={{ marginTop: '20px', marginBottom: '0px' }}>
                        Do you want to reschedule this orders?
                    </span>
                </div>
            </Modal>

        </>
    )
}

export default React.memo(SankalpNameTagging)
