import Stepper from '@/components/ui/Stepper'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Form, Input, message, Modal, Row, Spin } from 'antd'
import styled from 'styled-components'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'
import { CustomLoader } from '@/components/Loader'
import { generateReschedulePayload } from './helper'
import { ReschedulePayload } from '@a4b/api/src/modules/Monetisation/Puja/types/reschedule'

const InventoryMappingList = React.lazy(() => import('./InventoryMappingList'))

const Container = styled(Card)`
display: flex;
flex-direction: column;
background:#FAFAFA;

.ant-card-body {
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
`
interface Inventory {
    label: string
    value: string
    itemType: string
}

enum ItemType {
    POOJA = 'pooja',
    ITEM = 'item',
}

export type FormTypeValues = 'pooja' | 'order'
interface RescheduleFormProps {
    formType: FormTypeValues
    existingStoreId: string
    orderInventories?: Inventory[] | null
    formSubmitHandler: (data: ReschedulePayload) => Promise<{ success: boolean; message: string }>
    successCallBack: () => void
}

const RescheduleForm: React.FC<RescheduleFormProps> = ({ formType, existingStoreId, orderInventories, formSubmitHandler, successCallBack }) => {
    const { networkInstance, countryState, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [form] = Form.useForm()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [oldInventories, setOldInventories] = useState<any>()
    const [newInventories, setNewInventories] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const getStoreProductList = useCallback(
        async (storeId: string): Promise<Inventory[] | undefined> => {
            setLoading(true)
            try {
                const { data } = await monetisationPujaApi.storesApi.getProductList(
                    storeId,
                    countryState?.countryCode
                )
                return data?.data?.products?.map(product => {
                    const countryCode = product?.country_code === 'IN' ? 'India' : 'Global'
                    return {
                        label: `${product.title} + ${countryCode} + ${product?.id}`,
                        value: product.id,
                        itemType: product.item_type
                    }
                })
            } catch (error) {
                console.error(error)
                message.error('Error while fetching store products', 3)
                return undefined
            } finally {
                setLoading(false)
            }
        },
        [countryState?.countryCode, monetisationPujaApi.storesApi]
    )

    const categorizeInventories = (type: string, inventories: Inventory[]) =>
        inventories?.filter((inventory: Inventory) => inventory?.itemType === type)

    const setFormData = useCallback((storeId: string, inventories: Inventory[]) => {
        form.setFieldsValue({
            oldStoreId: storeId,
            pujaPackage: {
                oldInventories: categorizeInventories(ItemType.POOJA, inventories),
                newInventories: undefined,
            },
            addons: {
                oldInventories: categorizeInventories(ItemType.ITEM, inventories),
                newInventories: undefined,
            },
        })
    }, [form])

    const fetchData = useCallback(async (id: string) => {
        const inventories = await getStoreProductList(id)
        console.log('oldInventories', { inventories })
        if (inventories && inventories?.length > 0) {
            setFormData(id, inventories)
            setOldInventories(inventories)
        } else {
            message.warning('No inventories found for this store ID: ' + id, 3)
        }
    }, [getStoreProductList, setFormData])

    // Initial useEffect
    useEffect(() => {
        if (!existingStoreId) {
            message.warning("Store Id not found", 3)
            console.log({ existingStoreId })
            return
        }

        if (formType === 'pooja') {
            fetchData(existingStoreId)
        } else {
            if (!orderInventories?.length) {
                message.error('No Order Inventories found')
                return
            }
            setOldInventories(orderInventories)
            setFormData(existingStoreId, orderInventories)
        }
    }, [existingStoreId, fetchData, formType, getStoreProductList, orderInventories, setFormData])

    const fetchProductList = useCallback(async (id: string) => {
        const trimmedId = id?.trim()
        const data = await getStoreProductList(trimmedId)
        console.log({ data })
        if (data) {
            // TODO: return just empty states with same length for new inventories
            const poojaInventories = categorizeInventories(ItemType.POOJA, data)
            const addonInventories = categorizeInventories(ItemType.ITEM, data)

            const poojaOldInventories = categorizeInventories(ItemType.POOJA, oldInventories)
            const addonOldInventories = categorizeInventories(ItemType.ITEM, oldInventories)

            console.log({ poojaOldInventories }, { addonOldInventories })
            console.log(oldInventories)

            form.setFieldsValue({
                pujaPackage: { newInventories: poojaOldInventories?.map(() => ({})) },
                addons: { newInventories: addonOldInventories?.map(() => ({})) },
            })

            setNewInventories({
                newPoojaInventories: poojaInventories,
                newAddonInventories: addonInventories,
            })
        }
    }, [form, getStoreProductList, oldInventories])

    const formValidationHandler = useCallback((callback: () => void) => {
        form.validateFields()
            .then((values: any) => {
                console.log({ values })
                const pujaPackage = values?.pujaPackage
                const addOns = values?.addons
                if (pujaPackage) {
                    if (pujaPackage?.newInventories?.length !== pujaPackage?.oldInventories?.length) {
                        message.error('Old Package inventories and New Package inventories length should be equal', 3)
                        return
                    }
                    for (const inventory of pujaPackage?.newInventories) {
                        // Check if inventory is not empty and is a valid string
                        if (!inventory || typeof inventory !== 'string' || inventory?.trim() === '') {
                            message.error('Select new inventory item', 3);
                            return;
                        }
                    }
                }

                if (addOns) {
                    if (addOns?.newInventories?.length !== addOns?.oldInventories?.length) {
                        message.error('Old addons inventories and New addons inventories length should be equal', 3)
                        return
                    }
                    for (const inventory of addOns?.newInventories) {
                        console.log(typeof inventory, inventory)
                        // Check if inventory is not empty and is a valid string
                        if (!inventory || typeof inventory !== 'string' || inventory?.trim() === '') {
                            message.error('Select new inventory item', 3);
                            return;
                        }
                    }
                }
                callback()
            })
            .catch((error) => {
                console.log("Validation failed:", error)
            })
    }, [form])

    const previousHandler = useCallback(() => {
        formValidationHandler(() => setCurrentStep(currentStep - 1))
    }, [currentStep, formValidationHandler])

    const nextHandler = useCallback(() => {
        formValidationHandler(() => setCurrentStep(currentStep + 1))
    }, [currentStep, formValidationHandler])

    const submitHandler = useCallback(() => {
        formValidationHandler(() => setShowConfirmationModal(true))
    }, [formValidationHandler])

    const handleOk = useCallback(async () => {
        const allValues = form.getFieldsValue(true) // true: to get the all values in the form
        console.log("Combined Form Data:", allValues)


        const reschedulePayload = generateReschedulePayload(allValues);
        const response = await formSubmitHandler(reschedulePayload)

        if (response.success === false) {
            console.log({ response })
            return
        }

        // Clean up the form data
        form.resetFields()
        successCallBack()
    }, [form, formSubmitHandler, successCallBack])

    const handleCancelModal = () => { setShowConfirmationModal(false) }

    const stepList = useMemo(() => [
        {
            title: 'Puja package',
            description: '',
            content: (
                <Suspense fallback={<Spin indicator={<CustomLoader />} />}>
                    <InventoryMappingList
                        form={form}
                        fieldName="pujaPackage"
                        fullInventories={newInventories?.newPoojaInventories}
                        loading={loading}
                    />
                </Suspense>
            ),
        },
        {
            title: 'Add-ons',
            description: '',
            content: (
                <Suspense fallback={<Spin indicator={<CustomLoader />} />}>
                    <InventoryMappingList
                        form={form}
                        fieldName="addons"
                        fullInventories={newInventories?.newAddonInventories}
                    />
                </Suspense>
            ),
        },
    ], [form, loading, newInventories?.newAddonInventories, newInventories?.newPoojaInventories])

    return (
        <div>
            <Container>
                <Form
                    form={form}
                    initialValues={{ remember: true }}
                    layout="vertical"
                >
                    <Row justify='space-between'>
                        <Form.Item
                            name={'oldStoreId'}
                            label={`Old Store ID`}
                            rules={[{ required: true }]}
                            style={{ flexBasis: '48%' }}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            name={'newStoreId'}
                            label={`New Store ID`}
                            rules={[{ required: true }]}
                            style={{ flexBasis: '48%' }}
                        >
                            <Input.Search
                                placeholder="Input New Store ID"
                                loading={loading}
                                enterButton
                                onSearch={(value) => {
                                    if (!value) { message.warning('Please enter Store ID', 3); return }

                                    if (value === existingStoreId) {
                                        message.error('Old Store ID and New Store ID should be the same', 3)
                                        return
                                    }
                                    fetchProductList(value)
                                }}
                            />
                        </Form.Item>
                    </Row>
                </Form>
            </Container>
            <Stepper
                steps={stepList}
                currentStep={currentStep}
                title='Puja Reschedule'
            />
            <Container>
                <Row justify='center'>
                    {currentStep >= 1 &&
                        <Row justify='space-between' style={{ width: '200px' }}>
                            <Button type="primary" onClick={previousHandler}>
                                Previous
                            </Button>
                            <Button type="primary" onClick={submitHandler}>
                                Submit
                            </Button>
                        </Row>
                    }
                    {currentStep === 0 &&
                        <Button type="primary" onClick={nextHandler}>
                            Next
                        </Button>
                    }
                </Row>
            </Container>
            <Modal
                title={<span>Reschedule Confirmation</span>}
                visible={showConfirmationModal}
                onOk={handleOk}
                onCancel={handleCancelModal}
                okText='Reschedule'
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <span>
                        {` Do you want to reschedule this ${formType}?`}
                    </span>
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(RescheduleForm)